import { ReactNode, createContext, useContext, useState } from "react";
import moment from "moment";
import { DateRange } from "mui-daterange-picker";
import { parseCookies, setCookie } from "nookies";
import { useQuery as useTanstackQuery } from "@tanstack/react-query";
import { API } from "aws-amplify";

import { useAuth } from "./auth";
import { useAlerts } from "./alerts";

import { projectsByGroupIdForManager } from "graphql/queries";
import { Project, Schedule } from "API";
import {
  enumerateDaysBetweenDates,
  getCaseSchedule,
  getM3Schedule,
  getFocusedDateRange,
  sortProjects,
} from "utils/gantt";
import type {
  CaseSchedule,
  M3Schedule,
  ProjectDateSortName,
  VisibleKeyDates,
} from "utils/gantt/types";
import { ganttifyProject } from "utils/gantt";
import useQuery from "hooks/query";
import { Status, getStatusText } from "utils/status";
import { defaultCookieOptions, parseCookieBooleanValue } from "utils/cookie";
import { getStatusIndex } from "utils/status";
import { CustomError } from "utils/error";

interface GanttContextValue {
  projects: Project[];
  refetchProjects: () => Promise<void>;
  dateRange: DateRange;
  setDateRange: (range: DateRange) => void;
  //PAX-607 +7daysと+30daysを判別するためにdateLabelを追加
  dateLabel: string | null;
  setDateLabel: (k: string | null) => void;
  dates: string[];
  loading: boolean;
  focus: (schedule: Schedule) => void;
  m3: M3Schedule;
  cs: CaseSchedule;
  sort: ProjectDateSortName;
  setSort: (sort: ProjectDateSortName) => void;
  m3Visible: boolean;
  setM3Visible: (visible: boolean) => void;
  caseVisible: boolean;
  setCaseVisible: (visible: boolean) => void;
  visibleKeyDates: VisibleKeyDates;
  setVisibleKeyDates: (visibleKeyDates: VisibleKeyDates) => void;
  status: string[];
  setStatus: (status: string[]) => void;
  keyword: string | null;
  setKeyword: (k: string | null) => void;
  column: string[];
  setColumn: (column: string[]) => void;
  isFilterSaved: boolean;
  setIsFilterSaved: (visible: boolean) => void;
}

const GanttContext = createContext<GanttContextValue>({
  projects: [],
  refetchProjects: () => Promise.resolve(),
  dateRange: {
    startDate: moment().startOf("week").toDate(),
    endDate: moment().endOf("week").toDate(),
  },
  setDateRange: () => {},
  dateLabel: null,
  setDateLabel: () => null,
  dates: enumerateDaysBetweenDates(
    moment().startOf("week"),
    moment().endOf("week")
  ),
  focus: () => null,
  m3: {},
  cs: {},
  sort: "sortByName",
  setSort: () => {},
  m3Visible: true,
  setM3Visible: () => null,
  caseVisible: true,
  setCaseVisible: () => null,
  visibleKeyDates: {
    stockingDate: true,
    packagingDate: true,
    shippingDate: true,
    cutDate: true,
  },
  setVisibleKeyDates: () => null,
  status: [],
  setStatus: () => null,
  keyword: null,
  setKeyword: () => null,
  column: [],
  setColumn: () => null,
  isFilterSaved: false,
  setIsFilterSaved: () => null,
  loading: false,
});

const filterProjectByKeyword = (
  data: Project[],
  keyword: string | null,
  statusFilter: Status[]
) => {
  let projects = data;
  if (keyword)
    projects = projects.filter(
      (d: Project) =>
        d.name.includes(keyword) ||
        d.accountName?.includes(keyword) ||
        d.contactName?.includes(keyword) ||
        d.reference?.includes(keyword) ||
        getStatusText(d.status).includes(keyword)
    );
  if (statusFilter.length > 0) {
    projects = projects.filter((d: Project) => statusFilter.includes(d.status));
  }

  return projects;
};

export const GanttProvider = ({ children }: { children: ReactNode }) => {
  const cookies = parseCookies();
  const isFilterApplied = parseCookieBooleanValue(cookies.isFilterSaved);

  const { currentGroup } = useAuth();
  const [keyword, setKeyword] = useState<string | null>(
    isFilterApplied && cookies.ganttKeyword ? cookies.ganttKeyword : null
  );
  const [m3Visible, setM3Visible] = useQuery<boolean>("m3", true);
  const [caseVisible, setCaseVisible] = useQuery<boolean>("case", true);
  const [stock, setStock] = useQuery<boolean>("stock", true);
  const [pack, setPack] = useQuery<boolean>("pack", true);
  const [ship, setShip] = useQuery<boolean>("ship", true);
  const [cut, setCut] = useQuery<boolean>("cut", true);
  const [status, setStatus] = useQuery<string[]>(
    "status",
    isFilterApplied && cookies.ganttStatus
      ? cookies.ganttStatus?.split(",")
      : ["ORDER", "STOCK", "PACK", "SHIP"],
    (value) => value.split("_"),
    (value) => value.join("_")
  );
  const { addAlert } = useAlerts();

  // PAX-607 dateLabelをcookieから取得
  const [dateLabel, setDateLabel] = useState<string | null>(
    isFilterApplied && cookies.ganttDateLabel ? cookies.ganttDateLabel : null
  );
  // PAX-360 ピンが設定されてかつcookieにDate情報があればcookie内の日付に設定\
  // PAX-607 useQueryからuseStateに変更
  const [startDate, setStartDate] = useState<Date>(
    isFilterApplied && cookies.ganttStartDate
      ? moment(cookies.ganttStartDate).toDate()
      : moment().toDate()
  );

  const [endDate, setEndDate] = useState<Date>(
    isFilterApplied && cookies.ganttEndDate
      ? moment(cookies.ganttEndDate).toDate()
      : moment().add(7, "day").toDate()
  );

  const dateRange = { startDate, endDate };
  const dates = enumerateDaysBetweenDates(moment(startDate), moment(endDate));

  const visibleKeyDates = {
    stockingDate: stock,
    packagingDate: pack,
    shippingDate: ship,
    cutDate: cut,
  };

  const {
    data: filteredProjects = [],
    isLoading: loading,
    refetch,
  } = useTanstackQuery({
    queryKey: [
      "projectsByGroupIdForManager",
      currentGroup?.id,
      status,
      dates,
      visibleKeyDates,
    ],
    queryFn: async () => {
      let nextToken: string | null = null;
      let allItems: Project[] = [];
      try {
        while (true) {
          const res: any = await API.graphql({
            query: projectsByGroupIdForManager,
            variables: {
              groupId: currentGroup?.id,
              status,
              dates,
              visibleKeyDates,
              filter: { archived: { ne: true } },
              sortDirection: "DESC",
              nextToken,
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
          const items = res.data.projectsByGroupIdForManager.items;
          allItems = allItems.concat(items);
          nextToken = res.data.projectsByGroupIdForManager.nextToken;
          if (!nextToken) break;
        }
        return allItems.sort(
          (a, b) => getStatusIndex(a.status) - getStatusIndex(b.status)
        );
      } catch (err) {
        const customError = new CustomError(err, "get");
        addAlert({ message: customError.message, severity: "error" });
      }
    },
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 6,
  });

  const [sort, setSort] = useQuery<ProjectDateSortName>(
    "sort",
    isFilterApplied && cookies.ganttSort
      ? (cookies.ganttSort as ProjectDateSortName)
      : "sortByPackage"
  );
  const [column, setColumn] = useState<string[]>(
    isFilterApplied && cookies.ganttColumn
      ? cookies.ganttColumn?.split(",")
      : [
          "recentlyScheduleUpdated",
          "name",
          "reference",
          "accountName",
          "s.name",
        ]
  );

  // PAX-360 Gantt検索条件はCookieに保存する、そのトグル
  const [isFilterSaved, setIsFilterSaved] = useState<boolean>(isFilterApplied);

  if (isFilterSaved) {
    setCookie(null, "ganttStatus", status.join(","), defaultCookieOptions);
    setCookie(
      null,
      "ganttStartDate",
      startDate.toString(),
      defaultCookieOptions
    );
    setCookie(null, "ganttEndDate", endDate.toString(), defaultCookieOptions);
    setCookie(null, "ganttDateLabel", dateLabel ?? "", defaultCookieOptions); // PAX-607 dateLabelも合わせてCookieに保存
    setCookie(null, "ganttSort", sort, defaultCookieOptions);
    setCookie(null, "ganttColumn", column.join(","), defaultCookieOptions);
    setCookie(null, "ganttKeyword", keyword ?? "", defaultCookieOptions);
  }
  // PAX-360 Cookieには文字列しか格納できないため,文字列"true", "false"を利用
  setCookie(
    null,
    "isFilterSaved",
    isFilterSaved ? "true" : "false",
    defaultCookieOptions
  );

  // deep copy (projects.Schedulesの表示期間対応)
  const editProjects = structuredClone(filteredProjects);

  const m3 = getM3Schedule(editProjects);
  const cs = getCaseSchedule(editProjects);

  const keywordFilteredProjects = filterProjectByKeyword(
    editProjects,
    keyword,
    status
  );

  // projects[]以下にbeforeIdxというパラメータがあったらscheduleのドラッグ＆ドロップで更新されたものとして仕分ける
  const dndProjects = keywordFilteredProjects.filter(
    (p: any) => p.beforeIdx !== undefined
  );
  const noDndProjects = keywordFilteredProjects.filter(
    (p: any) => p.beforeIdx === undefined
  );

  // ドラッグ＆ドロップの対象になってないのはsortを行う
  const sortedNoDndProjects = sortProjects(noDndProjects, sort);
  // ドラッグ＆ドロップ対象はbeforeIdxが小さい順に挿入していくので、まずはソート
  const sortedDndProjects = dndProjects.sort((a: any, b: any) => {
    return a.beforeIdx - b.beforeIdx;
  });
  // ドラッグ＆ドロップ対象の中でbeforeIdxが小さい順に挿入していく
  sortedDndProjects.forEach((p: any) => {
    sortedNoDndProjects.splice(p.beforeIdx, 0, p);
  });

  const processProject = (project: Project) => {
    return {
      ...project,
      ...ganttifyProject(project, dates),
    };
  };

  const projects = sortedNoDndProjects.map(processProject);

  const focusSchedule = (schedule: Schedule) => {
    const { startDate, endDate } = getFocusedDateRange(
      schedule,
      dates,
      visibleKeyDates
    );
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const setDateRange = ({ startDate, endDate }: DateRange) => {
    setStartDate(startDate || new Date());
    setEndDate(endDate || new Date());
  };

  const setProjectStatus = (status: string[]) => {
    setStatus(status);
  };

  const setVisibleKeyDates = (visibleKeyDates: VisibleKeyDates) => {
    setStock(visibleKeyDates.stockingDate);
    setPack(visibleKeyDates.packagingDate);
    setShip(visibleKeyDates.shippingDate);
    setCut(visibleKeyDates.cutDate);
  };

  return (
    <GanttContext.Provider
      value={{
        projects,
        loading,
        refetchProjects: async () => {
          refetch();
        },
        dateRange,
        setDateRange,
        dateLabel,
        setDateLabel,
        dates,
        focus: focusSchedule,
        sort,
        setSort,
        m3,
        cs,
        m3Visible,
        setM3Visible,
        caseVisible,
        setCaseVisible,
        visibleKeyDates,
        setVisibleKeyDates,
        status,
        setStatus: setProjectStatus,
        keyword,
        setKeyword,
        column,
        setColumn,
        isFilterSaved,
        setIsFilterSaved,
      }}
    >
      {children}
    </GanttContext.Provider>
  );
};

export const useGantt = () => {
  const accountsContext = useContext(GanttContext);

  if (accountsContext === undefined) {
    throw new Error("useGantt must be within AuthProvider");
  }

  return accountsContext;
};
