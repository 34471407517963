import moment from "moment";

import { Project, Schedule } from "API";
import { getStatusText, getStatusInitial, getStatusColor } from "utils/status";

export type ScheduleDate = {
  hasData: boolean;
  type: string;
  text: string;
  initial: string;
  color: string;
};

// ステータスを出力する。出荷はスケジュールの出荷方法を出力する。
export function getCustomStatusText(status: string, schedule: Schedule) {
  switch (status) {
    case "SHIP":
      return schedule.shipType;
    default:
      return getStatusText(status);
  }
}

// ステータスを出力する(initial)。出荷はスケジュールの出荷方法を出力する。
export function getCustomStatusInitialText(status: string, schedule: Schedule) {
  switch (status) {
    case "SHIP":
      return schedule.shipType?.charAt(0);
    default:
      return getStatusInitial(status);
  }
}

export function getFillerColor(status: string) {
  switch (status) {
    case "STOCKING":
      return "#29BF12";
    case "PACKING":
      return "#3E6EB3";
    case "SHIPPING":
      return "#D18643";
    case "CUTTING":
      return "#bd3993";
    default:
      return "transparent";
  }
}

export const getScheduleDates = (schedule: Schedule, dates: string[]) => {
  return dates.reduce((acc, date) => {
    let data = "";

    const target = moment(date);
    const stock = moment(schedule.stockingDate);
    const pkg = moment(schedule.packagingDate);
    const ship = moment(schedule.shippingDate);
    const cut = moment(schedule.cutDate);

    if (schedule.stockingDate === date) data = "STOCK";
    if (schedule.packagingDate === date) data = "PACK";
    if (schedule.shippingDate === date) data = "SHIP";
    if (schedule.shipType === "バン" && schedule.cutDate === date) data = "CUT";

    if (data) {
      return {
        ...acc,
        [date]: {
          hasData: !!data,
          type: data,
          text: data && getCustomStatusText(data, schedule),
          initial: data && getCustomStatusInitialText(data, schedule),
          color: data && getStatusColor(data),
          m3: schedule.m3 || 0,
          case: schedule.case || 0,
        },
      };
    }

    // filler
    // 案件タイプ=その他の場合は梱包日がないため、別に判定する
    const isPkg = schedule.packagingDate ? true : false;

    if (isPkg && target.isBetween(stock, pkg, "d", "[]")) data = "PACKING";
    if (isPkg && target.isBetween(pkg, ship, "d", "[]")) data = "SHIPPING";
    if (!isPkg && target.isBetween(stock, ship, "d", "[]")) data = "SHIPPING";
    if (schedule.shipType === "バン" && target.isBetween(ship, cut, "d", "[]"))
      data = "CUTTING";

    return {
      ...acc,
      [date]: {
        hasData: false,
        type: data,
        text: "",
        initial: "",
        color: data && getFillerColor(data),
      },
    };
  }, {});
};

export const ganttifyProject = (project: Project, dates: string[]) => {
  if (!project.schedules) return project;
  const schedules = project.schedules?.items.reduce((acc, schedule) => {
    if (!schedule) return acc;
    const scheduleDates = getScheduleDates(schedule, dates);
    (acc as any).push({ ...schedule, ...scheduleDates });
    return acc;
  }, []);
  project.schedules.items = schedules;
  return project;
};
